import Amplify, { Auth } from "aws-amplify";
import React from "react";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import "./index.css";
import { Router } from "./routes";
import {
  Grid,
  View,
  AmplifyProvider,
  createTheme,
  defaultTheme,
  Authenticator,
  Image,
  useAuthenticator,
  useTheme,
  Heading,
  Flex,
} from "@aws-amplify/ui-react";
import { authAnalytics } from "./features/analytics";
import { setup as setupi18n } from "./i18n";
import bg from "./chf-background.jpg";
import Logo from "./logo.png";
import styled from "styled-components";

const config = {
  ...awsExports,
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_CASE_OUTPUT_BUCKET,
      region: "eu-central-1",
    },
  },
};

Amplify.configure(config);
Auth.configure(config);
setupi18n();

const theme = createTheme({
  name: "dark-mode-theme",
  overrides: [
    {
      colorMode: "dark",
      tokens: {
        colors: {
          neutral: {
            // flipping the neutral palette
            10: defaultTheme.tokens.colors.neutral[100],
            20: defaultTheme.tokens.colors.neutral[90],
            40: defaultTheme.tokens.colors.neutral[80],
            80: defaultTheme.tokens.colors.neutral[40],
            90: defaultTheme.tokens.colors.neutral[20],
            100: defaultTheme.tokens.colors.neutral[10],
          },
          black: { value: "#fff" },
          white: { value: "#000" },
        },
      },
    },
  ],
});

const App = () => {
  React.useEffect(() => {
    authAnalytics();
  }, []);
  const { signOut, route } = useAuthenticator((context) => [context.route]);

  return (
    <AmplifyProvider theme={theme}>
      {route === "authenticated" ? (
        <Router signOut={signOut} />
      ) : (
        <SignInView />
      )}
    </AmplifyProvider>
  );
};
const Main = () => (
  <Authenticator.Provider>
    <App />
  </Authenticator.Provider>
);

export default Main;

const LogoImage = styled(Image)`
  height: 4rem;
  margin-bottom: 2rem;
`;

const SignInView = () => {
  const authenticatorComponents = {
    Header: () => {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <LogoImage alt="Bruco Rapido logo" src={Logo} />
          <Heading level={3}>Analiza kredytu CHF</Heading>
        </View>
      );
    },
  };
  return (
    <Grid templateColumns={{ base: "1fr 0", medium: "1fr 1fr" }}>
      <Flex
        backgroundColor={theme.tokens.colors.background.secondary}
        justifyContent="center"
      >
        <Authenticator components={authenticatorComponents} />
      </Flex>
      <View height="100vh">
        <img
          src={bg}
          width="100%"
          height="100%"
          alt={"CHF"}
          style={{ objectFit: "cover" }}
        />
      </View>
    </Grid>
  );
};
