import React from "react";
import styled from "styled-components";
import { Case, CaseList, CaseDetail, CaseNew } from "../pages/cases";
import { Link, Menu, MenuButton, MenuItem } from "@aws-amplify/ui-react";
import Logo from "../logo.png";
import "@aws-amplify/ui-react/styles.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Link as ReactRouterLink,
  useResolvedPath,
  useMatch,
  Navigate,
} from "react-router-dom";
import { AlertBox } from "../components/alerts";
import { useState } from "react";
import { useEffect } from "react";
import { Auth } from "aws-amplify";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: var(--amplify-space-small) var(--amplify-space-xl);
  justify-content: space-between;
  position: sticky;
  top: 0px;
  background-color: white;
  box-shadow: 5px 4px 16px 0px rgba(66, 68, 90, 0.28);
`;

const Right = styled.div`
  display: flex;
  align-items: center;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const LogoImage = styled.img`
  height: 3rem;
`;

const NavButton = styled.a<{ isPressed: boolean }>`
  padding: var(--amplify-space-xs) var(--amplify-space-small);
  text-decoration: none;
  color: var(--amplify-colors-font-hover);
  border-radius: var(--amplify-components-button-border-radius);
  border: ${(props) =>
    props.isPressed
      ? "var(--amplify-components-button-primary-border-width) solid var(--amplify-components-button-hover-border-color)"
      : "var(--amplify-components-button-primary-border-width) solid clear"};
  display: inline-block;
  font-weight: var(--amplify-components-button-font-weight);
  cursor: pointer;
  &:hover {
    background-color: var(--amplify-colors-brand-primary-10);
  }
`;

const Container = styled.div``;

// TODO: Use amplify CSS variable for max-width (if available).
const ContentContainer = styled.div`
  max-width: 992px; /* equal to large breakpoint value */
  margin: auto;
`;

const NavLink = ({ to, name }: { to: string; name: string }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    // @ts-ignore
    <Link as={ReactRouterLink} to={to}>
      <NavButton isPressed={!!match}>{name}</NavButton>
    </Link>
  );
};

const Profile = ({ signOut }: { signOut: () => void }) => {
  const [email, setEmail] = useState("");
  useEffect(() => {
    Auth.currentUserInfo().then((userInfo) => {
      setEmail(userInfo.attributes.email);
    });
  }, [setEmail]);
  return (
    <Menu
      trigger={
        <MenuButton variation="link" size="small">
          {email}
        </MenuButton>
      }
    >
      <MenuItem onClick={signOut}>Wyloguj</MenuItem>
    </Menu>
  );
};

export const Router = ({ signOut }: { signOut: () => void }) => {
  return (
    <Container>
      <BrowserRouter>
        <Header>
          <Left>
            <Link as={ReactRouterLink} to={"/case"}>
              <LogoImage src={Logo} />
            </Link>
            <NavLink to="/case" name="Lista spraw" />
          </Left>
          <Right>
            <Profile signOut={signOut} />
          </Right>
        </Header>
        <AlertBox>
          <ContentContainer>
            <Routes>
              <Route index element={<Navigate to="/case" />} />
              <Route path="/case" element={<Case />}>
                <Route index element={<CaseList />} />
                <Route path="list" element={<CaseList />} />
                <Route path="new" element={<CaseNew />} />
                <Route path=":caseId" element={<CaseDetail />} />
              </Route>
            </Routes>
          </ContentContainer>
        </AlertBox>
      </BrowserRouter>
    </Container>
  );
};
