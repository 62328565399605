import {
  Heading,
  useTheme,
  Text,
  AmplifyProvider,
} from "@aws-amplify/ui-react";
import styled from "styled-components";
import "@aws-amplify/ui-react/styles.css";
import { CreateCaseState } from "./side-effects";
import animationData from "../../../4922-search-files.json";
import Lottie from "react-lottie";

interface Props {
  state: CreateCaseState;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: #3a6175;
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const headingText = (state: CreateCaseState) => {
  switch (state) {
    case CreateCaseState.PROCESSING:
      return "Przetwarzanie...";
    case CreateCaseState.UPLOADING:
      return "Wysyłanie...";
    default:
      return "";
  }
};

const subtitleText = (state: CreateCaseState) => {
  switch (state) {
    case CreateCaseState.PROCESSING:
      return "Może to potrwać kilka minut.";
    default:
      return "";
  }
};

export const Loading = ({ state }: Props) => {
  const theme = useTheme();
  return (
    <AmplifyProvider theme={theme} colorMode={"dark"}>
      <Container>
        <Content>
          <Lottie options={defaultOptions} height={400} width={400} />
          <Heading level={4}>{headingText(state)}</Heading>
          <Text>{subtitleText(state)}</Text>
        </Content>
      </Container>
    </AmplifyProvider>
  );
};
