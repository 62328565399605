import { Flex, Heading } from "@aws-amplify/ui-react";
import styled from "styled-components";

interface Props {
  title: string;
}

const EmptyStateContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  min-height: 25vh;
`;

const EmptyStateLabel = styled(Heading)`
  color: var(--amplify-colors-border-secondary);
`;

export const EmptyState = ({ title }: Props) => (
  <EmptyStateContainer>
    <EmptyStateLabel level={4}>{title}</EmptyStateLabel>
  </EmptyStateContainer>
);
