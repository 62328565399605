import { CreateExcelJobStatus } from "../API";

export const isExcelJobStatusFinished = (status: CreateExcelJobStatus) => {
  switch (status) {
    case CreateExcelJobStatus.COMPLETED:
    case CreateExcelJobStatus.FAILED_EXCEL_ALREADY_SIGNED:
    case CreateExcelJobStatus.FAILED_TEXTRACT:
    case CreateExcelJobStatus.FAILED:
      return true;
    default:
      return false;
  }
};

export const isExcelJobStatusFailed = (status: CreateExcelJobStatus) => {
  switch (status) {
    case CreateExcelJobStatus.FAILED_EXCEL_ALREADY_SIGNED:
    case CreateExcelJobStatus.FAILED_TEXTRACT:
    case CreateExcelJobStatus.FAILED:
      return true;
    default:
      return false;
  }
};
