/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCase = /* GraphQL */ `
  mutation CreateCase(
    $input: CreateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    createCase(input: $input, condition: $condition) {
      id
      name
      date
      companyId
      partitionKey
      createExcelJob {
        id
        status
        date
        companyId
        owner
        ownerEmail
        inputType
        outputKey
        case {
          id
          name
          date
          companyId
          partitionKey
          owner
          ownerEmail
          createdAt
          updatedAt
          caseCreateExcelJobId
        }
        createdAt
        updatedAt
        createExcelJobCaseId
      }
      reports {
        items {
          id
          status
          date
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          date
          type
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      ownerEmail
      createdAt
      updatedAt
      caseCreateExcelJobId
    }
  }
`;
export const updateCase = /* GraphQL */ `
  mutation UpdateCase(
    $input: UpdateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    updateCase(input: $input, condition: $condition) {
      id
      name
      date
      companyId
      partitionKey
      createExcelJob {
        id
        status
        date
        companyId
        owner
        ownerEmail
        inputType
        outputKey
        case {
          id
          name
          date
          companyId
          partitionKey
          owner
          ownerEmail
          createdAt
          updatedAt
          caseCreateExcelJobId
        }
        createdAt
        updatedAt
        createExcelJobCaseId
      }
      reports {
        items {
          id
          status
          date
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          date
          type
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      ownerEmail
      createdAt
      updatedAt
      caseCreateExcelJobId
    }
  }
`;
export const deleteCase = /* GraphQL */ `
  mutation DeleteCase(
    $input: DeleteCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    deleteCase(input: $input, condition: $condition) {
      id
      name
      date
      companyId
      partitionKey
      createExcelJob {
        id
        status
        date
        companyId
        owner
        ownerEmail
        inputType
        outputKey
        case {
          id
          name
          date
          companyId
          partitionKey
          owner
          ownerEmail
          createdAt
          updatedAt
          caseCreateExcelJobId
        }
        createdAt
        updatedAt
        createExcelJobCaseId
      }
      reports {
        items {
          id
          status
          date
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          date
          type
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      ownerEmail
      createdAt
      updatedAt
      caseCreateExcelJobId
    }
  }
`;
export const createCaseEvent = /* GraphQL */ `
  mutation CreateCaseEvent(
    $input: CreateCaseEventInput!
    $condition: ModelCaseEventConditionInput
  ) {
    createCaseEvent(input: $input, condition: $condition) {
      id
      date
      type
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const updateCaseEvent = /* GraphQL */ `
  mutation UpdateCaseEvent(
    $input: UpdateCaseEventInput!
    $condition: ModelCaseEventConditionInput
  ) {
    updateCaseEvent(input: $input, condition: $condition) {
      id
      date
      type
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const deleteCaseEvent = /* GraphQL */ `
  mutation DeleteCaseEvent(
    $input: DeleteCaseEventInput!
    $condition: ModelCaseEventConditionInput
  ) {
    deleteCaseEvent(input: $input, condition: $condition) {
      id
      date
      type
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const createCreateExcelJob = /* GraphQL */ `
  mutation CreateCreateExcelJob(
    $input: CreateCreateExcelJobInput!
    $condition: ModelCreateExcelJobConditionInput
  ) {
    createCreateExcelJob(input: $input, condition: $condition) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      inputType
      outputKey
      case {
        id
        name
        date
        companyId
        partitionKey
        createExcelJob {
          id
          status
          date
          companyId
          owner
          ownerEmail
          inputType
          outputKey
          createdAt
          updatedAt
          createExcelJobCaseId
        }
        reports {
          nextToken
        }
        events {
          nextToken
        }
        owner
        ownerEmail
        createdAt
        updatedAt
        caseCreateExcelJobId
      }
      createdAt
      updatedAt
      createExcelJobCaseId
    }
  }
`;
export const updateCreateExcelJob = /* GraphQL */ `
  mutation UpdateCreateExcelJob(
    $input: UpdateCreateExcelJobInput!
    $condition: ModelCreateExcelJobConditionInput
  ) {
    updateCreateExcelJob(input: $input, condition: $condition) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      inputType
      outputKey
      case {
        id
        name
        date
        companyId
        partitionKey
        createExcelJob {
          id
          status
          date
          companyId
          owner
          ownerEmail
          inputType
          outputKey
          createdAt
          updatedAt
          createExcelJobCaseId
        }
        reports {
          nextToken
        }
        events {
          nextToken
        }
        owner
        ownerEmail
        createdAt
        updatedAt
        caseCreateExcelJobId
      }
      createdAt
      updatedAt
      createExcelJobCaseId
    }
  }
`;
export const deleteCreateExcelJob = /* GraphQL */ `
  mutation DeleteCreateExcelJob(
    $input: DeleteCreateExcelJobInput!
    $condition: ModelCreateExcelJobConditionInput
  ) {
    deleteCreateExcelJob(input: $input, condition: $condition) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      inputType
      outputKey
      case {
        id
        name
        date
        companyId
        partitionKey
        createExcelJob {
          id
          status
          date
          companyId
          owner
          ownerEmail
          inputType
          outputKey
          createdAt
          updatedAt
          createExcelJobCaseId
        }
        reports {
          nextToken
        }
        events {
          nextToken
        }
        owner
        ownerEmail
        createdAt
        updatedAt
        caseCreateExcelJobId
      }
      createdAt
      updatedAt
      createExcelJobCaseId
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
