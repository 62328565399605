import * as mixpanel from "mixpanel-browser";
import { Hub } from "aws-amplify";
import { getUserCompanyId } from "../../common/user";

export enum Event {
  LoggedIn = "Logged In",
  LoggedOut = "Logged Out",
  CreatedExcelJob = "Added Create Excel Job",
  ExcelDownloaded = "Excel Downloaded",
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "");

export const authAnalytics = () => {
  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signIn":
        const user = data.payload.data;
        const username = user?.username;
        const companyId = getUserCompanyId(user);
        mixpanel.identify(username);
        mixpanel.people.set({
          CompanyId: companyId,
          $email: user?.attributes.email,
        });
        mixpanel.track(Event.LoggedIn, { origin: "panel" });
        break;
      case "signOut":
        mixpanel.track(Event.LoggedOut, { origin: "panel" });
        mixpanel.reset();
        break;
      default:
        break;
    }
  });
};
