/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCase = /* GraphQL */ `
  query GetCase($id: ID!) {
    getCase(id: $id) {
      id
      name
      date
      companyId
      partitionKey
      createExcelJob {
        id
        status
        date
        companyId
        owner
        ownerEmail
        inputType
        outputKey
        case {
          id
          name
          date
          companyId
          partitionKey
          owner
          ownerEmail
          createdAt
          updatedAt
          caseCreateExcelJobId
        }
        createdAt
        updatedAt
        createExcelJobCaseId
      }
      reports {
        items {
          id
          status
          date
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          date
          type
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      ownerEmail
      createdAt
      updatedAt
      caseCreateExcelJobId
    }
  }
`;
export const listCases = /* GraphQL */ `
  query ListCases(
    $id: ID
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCases(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        date
        companyId
        partitionKey
        createExcelJob {
          id
          status
          date
          companyId
          owner
          ownerEmail
          inputType
          outputKey
          createdAt
          updatedAt
          createExcelJobCaseId
        }
        reports {
          nextToken
        }
        events {
          nextToken
        }
        owner
        ownerEmail
        createdAt
        updatedAt
        caseCreateExcelJobId
      }
      nextToken
    }
  }
`;
export const caseByCompanyIdAndDate = /* GraphQL */ `
  query CaseByCompanyIdAndDate(
    $companyId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    caseByCompanyIdAndDate(
      companyId: $companyId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        date
        companyId
        partitionKey
        createExcelJob {
          id
          status
          date
          companyId
          owner
          ownerEmail
          inputType
          outputKey
          createdAt
          updatedAt
          createExcelJobCaseId
        }
        reports {
          nextToken
        }
        events {
          nextToken
        }
        owner
        ownerEmail
        createdAt
        updatedAt
        caseCreateExcelJobId
      }
      nextToken
    }
  }
`;
export const casesByDate = /* GraphQL */ `
  query CasesByDate(
    $partitionKey: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    casesByDate(
      partitionKey: $partitionKey
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        date
        companyId
        partitionKey
        createExcelJob {
          id
          status
          date
          companyId
          owner
          ownerEmail
          inputType
          outputKey
          createdAt
          updatedAt
          createExcelJobCaseId
        }
        reports {
          nextToken
        }
        events {
          nextToken
        }
        owner
        ownerEmail
        createdAt
        updatedAt
        caseCreateExcelJobId
      }
      nextToken
    }
  }
`;
export const getCaseEvent = /* GraphQL */ `
  query GetCaseEvent($id: ID!) {
    getCaseEvent(id: $id) {
      id
      date
      type
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const listCaseEvents = /* GraphQL */ `
  query ListCaseEvents(
    $id: ID
    $filter: ModelCaseEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCaseEvents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        type
        companyId
        owner
        ownerEmail
        caseId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const caseEventsByCaseIdAndDate = /* GraphQL */ `
  query CaseEventsByCaseIdAndDate(
    $caseId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    caseEventsByCaseIdAndDate(
      caseId: $caseId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        type
        companyId
        owner
        ownerEmail
        caseId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCreateExcelJob = /* GraphQL */ `
  query GetCreateExcelJob($id: ID!) {
    getCreateExcelJob(id: $id) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      inputType
      outputKey
      case {
        id
        name
        date
        companyId
        partitionKey
        createExcelJob {
          id
          status
          date
          companyId
          owner
          ownerEmail
          inputType
          outputKey
          createdAt
          updatedAt
          createExcelJobCaseId
        }
        reports {
          nextToken
        }
        events {
          nextToken
        }
        owner
        ownerEmail
        createdAt
        updatedAt
        caseCreateExcelJobId
      }
      createdAt
      updatedAt
      createExcelJobCaseId
    }
  }
`;
export const listCreateExcelJobs = /* GraphQL */ `
  query ListCreateExcelJobs(
    $id: ID
    $filter: ModelCreateExcelJobFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCreateExcelJobs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        date
        companyId
        owner
        ownerEmail
        inputType
        outputKey
        case {
          id
          name
          date
          companyId
          partitionKey
          owner
          ownerEmail
          createdAt
          updatedAt
          caseCreateExcelJobId
        }
        createdAt
        updatedAt
        createExcelJobCaseId
      }
      nextToken
    }
  }
`;
export const jobsByCompanyIdAndDate = /* GraphQL */ `
  query JobsByCompanyIdAndDate(
    $companyId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreateExcelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByCompanyIdAndDate(
      companyId: $companyId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        date
        companyId
        owner
        ownerEmail
        inputType
        outputKey
        case {
          id
          name
          date
          companyId
          partitionKey
          owner
          ownerEmail
          createdAt
          updatedAt
          caseCreateExcelJobId
        }
        createdAt
        updatedAt
        createExcelJobCaseId
      }
      nextToken
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $id: ID
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReports(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        date
        companyId
        owner
        ownerEmail
        caseId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reportsByCompanyIdAndDate = /* GraphQL */ `
  query ReportsByCompanyIdAndDate(
    $companyId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByCompanyIdAndDate(
      companyId: $companyId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        date
        companyId
        owner
        ownerEmail
        caseId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reportsByCaseIdAndDate = /* GraphQL */ `
  query ReportsByCaseIdAndDate(
    $caseId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByCaseIdAndDate(
      caseId: $caseId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        date
        companyId
        owner
        ownerEmail
        caseId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
