import { useState, useEffect, useCallback } from "react";
import {
  Case,
  CaseEventType,
  Report,
  ReportsByCaseIdAndDateQuery,
  ReportStatus,
} from "../../../API";
import { API, Storage, Auth } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { reportsByCaseIdAndDate } from "../../../graphql/queries";
import {
  Text,
  Heading,
  Placeholder,
  Image,
  Flex,
  Loader,
  Button,
  Badge,
} from "@aws-amplify/ui-react";
import styled from "styled-components";
import { REPORT_FILE_NAME } from "../../../common/constants";
import { downloadBlob } from "../../../utils/download";
import { createCaseEvent } from "../../../common/api";
import { onCreateReport, onUpdateReport } from "../../../graphql/subscriptions";
import Observable from "zen-observable-ts";
import PdfImage from "../../../pdf.png";

const Container = styled(Flex)``;

const ReportImage = styled(Image)`
  height: 6rem;
`;

const ReportUnavailableImage = styled(Image)`
  height: 6rem;
  filter: grayscale(100%);
  opacity: 0.3;
`;

const LargeLoader = styled(Loader)`
  height: 3rem;
  margin: 1rem;
`;

const fetchReports = async (caseId: string): Promise<[Report] | null> => {
  try {
    const response = (await API.graphql({
      query: reportsByCaseIdAndDate,
      variables: {
        caseId,
        sortDirection: "DESC",
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as GraphQLResult<ReportsByCaseIdAndDateQuery>;
    return response.data?.reportsByCaseIdAndDate?.items as [Report];
  } catch (err) {
    console.warn("error fetching data..", err);
    return null;
  }
};

const downloadReport = async (key: string, userFileName: string) => {
  const result = await Storage.get(key, {
    customPrefix: { public: "" },
    download: true,
    bucket: process.env.REACT_APP_REPORT_OUTPUT_BUCKET,
  });
  downloadBlob(result.Body, userFileName);
};

const Right = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 456px;
  align-items: flex-start;
  justify-content: center;
  gap: var(--amplify-space-small);
`;

export const Reports = ({ caseObject }: { caseObject: Case }) => {
  const caseId = caseObject.id;
  const [report, setReport] = useState<Report | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [owner, setOwner] = useState<string | null>(null);
  useEffect(() => {
    Auth.currentAuthenticatedUser().then(({ username }) => {
      setOwner(username);
    });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    fetchReports(caseId).then((data) => {
      if (!!data && data.length > 0) {
        setReport(data[0]);
      }
      setIsLoading(false);
    });
  }, [setReport, caseId]);

  const download = useCallback(() => {
    if (!report) {
      return;
    }
    downloadReport(
      `${report.id}/${REPORT_FILE_NAME}`,
      downloadFileName(caseObject.name)
    );
    createCaseEvent({ caseId, type: CaseEventType.DOWNLOADED_REPORT });
  }, [report, caseId, caseObject]);

  useEffect(() => {
    if (!owner) {
      return;
    }
    const onCreateObservable = API.graphql({
      query: onCreateReport,
      variables: { owner },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }) as Observable<any>;
    const onCreateSubscription = onCreateObservable.subscribe({
      next: (event: any) => {
        const newReport = event.value.data.onCreateReport as Report;
        if (!newReport) {
          console.error(`onCreateReport undefined for event: ${event}`);
          return;
        }
        if (newReport.caseId !== caseId) {
          console.log("new report for different caseId");
          return;
        }
        setReport(newReport);
      },
      error: (error) => console.warn(error),
    });

    return () => {
      onCreateSubscription.unsubscribe();
    };
  }, [owner, setReport, caseId]);

  useEffect(() => {
    if (!owner) {
      return;
    }
    const onUpdateObservable = API.graphql({
      query: onUpdateReport,
      variables: { owner },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }) as Observable<any>;
    const onUpdateSubscription = onUpdateObservable.subscribe({
      next: (event: any) => {
        const updatedReport = event.value.data.onUpdateReport as Report;
        if (!updatedReport) {
          console.error(`onCreateReport undefined for event: ${event}`);
          return;
        }
        if (!!report && report.id === updatedReport.id) {
          setReport(updatedReport);
        }
      },
      error: (error) => console.warn(error),
    });

    return () => {
      onUpdateSubscription.unsubscribe();
    };
  }, [owner, report, setReport]);

  if (isLoading) {
    return (
      <>
        <Placeholder />
        <Placeholder />
        <Placeholder />
      </>
    );
  }

  return (
    <Container direction={"column"}>
      <Heading level={5}>Raport</Heading>
      <Flex>
        {report ? (
          <ReportImage src={PdfImage} alt="Raport PDF" />
        ) : (
          <ReportUnavailableImage src={PdfImage} alt="Raport PDF" />
        )}
        <Right>
          <Status report={report} onDownload={download} />
        </Right>
      </Flex>
    </Container>
  );
};

const downloadFileName = (caseName: string) => caseName + " raport.pdf";

const Status = ({
  report,
  onDownload,
}: {
  report?: Report;
  onDownload: () => void;
}) => {
  if (!report) {
    return (
      <Text>
        Aby wygenerować raport przeprowadź analizę kredytu w dodatku MS Excel i
        kliknij "Generuj raport".
      </Text>
    );
  }
  const date = new Date(report.date);
  switch (report.status) {
    case ReportStatus.UPLOADED:
    case ReportStatus.PROCESSING:
      // @ts-ignore
      return <LargeLoader />;
    case ReportStatus.COMPLETED:
      return (
        <>
          <Button onClick={onDownload} variation={"primary"}>
            {"Pobierz plik"}
          </Button>
          <Text>Wygenerowano: {date.toLocaleString()}</Text>
        </>
      );
    case ReportStatus.FAILED:
      return (
        <>
          <Badge variation="error">Błąd generowania raportu</Badge>
          <Text>Data wystąpienia: {date.toLocaleString()}</Text>
        </>
      );
  }
};
