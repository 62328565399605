import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, Auth } from "aws-amplify";
import { CaseEventType } from "../API";
import { userCompanyId } from "./user";

export type CreateCaseEventInput = {
  type: CaseEventType;
  caseId: string;
};

export const createCaseEvent = async (
  eventInput: CreateCaseEventInput
): Promise<{ id: string } | null> => {
  const ownerInfo = await userInfo();
  const input = {
    ...eventInput,
    ...ownerInfo,
    date: new Date().toISOString(),
  };

  const mutation = `
     mutation CreateCaseEvent(
      $input: CreateCaseEventInput!
      $condition: ModelCaseEventConditionInput
    ) {
      createCaseEvent(input: $input, condition: $condition) {
        id
      }
    }
   `;
  const result = (await API.graphql({
    query: mutation,
    variables: { input },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })) as GraphQLResult<{ id: string }>;
  console.log("Creating case event", input);
  return result.data || null;
};

const userInfo = async (): Promise<{
  companyId: string;
  ownerEmail: string;
  owner: string;
}> => {
  const companyId = await userCompanyId();
  const userInfo = await Auth.currentUserInfo();
  return {
    companyId: companyId || "",
    ownerEmail: userInfo.attributes.email,
    owner: userInfo.username,
  };
};
