import React, { useEffect, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import { Case, GetCaseQuery } from "../../../API";
import { Text, Placeholder, View, Heading } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { DownloadExcel } from "./download-excel";
import { Reports } from "./reports";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { EventLog } from "./event-log";
import { getCase } from "../../../graphql/queries";
import {
  EXCEL_OUTPUT_EXTENSION,
  EXCEL_OUTPUT_S3_FILE_NAME,
} from "../../../common/constants";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: var(--amplify-space-medium);
  flex-direction: column;
`;

const ExcelSection = styled(View)`
  padding-left: var(--amplify-space-medium);
`;

const Section = styled(View)`
  margin-top: var(--amplify-space-medium);
  border: solid 1pt #ffffff;
  padding: var(--amplify-space-medium);
  box-shadow: 5px 4px 16px 0px rgba(66, 68, 90, 0.28);
  border-radius: 4pt;
`;

const fetchCase = async (id: string): Promise<Case | null | undefined> => {
  try {
    const response = (await API.graphql({
      query: getCase,
      variables: {
        id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as GraphQLResult<GetCaseQuery>;
    // TODO: reports?
    return response.data?.getCase as Case;
  } catch (err) {
    console.warn("error fetching data..", err);
    return null;
  }
};

export const Detail = ({ caseId }: { caseId: string | undefined }) => {
  const [data, setData] = useState<Case | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!caseId) {
      setData(undefined);
      return;
    }
    setIsLoading(true);
    fetchCase(caseId).then((data) => {
      if (!!data) {
        setData(data);
      }
      setIsLoading(false);
    });
  }, [setData, caseId]);
  if (!isLoading && !data) {
    // TODO:
    return <>404</>;
  }
  if (isLoading) {
    return (
      <>
        <Placeholder />
        <Placeholder />
        <Placeholder />
      </>
    );
  }
  const date = new Date(data!.date);
  return (
    <Container>
      <div>
        <Heading level={3}>{data!.name}</Heading>
        <Text>{`Data utworzenia: ${date.toLocaleString()}`}</Text>
      </div>
      <ExcelSection>
        <DownloadExcel
          caseId={data!.id}
          s3Key={[data!.createExcelJob.id, EXCEL_OUTPUT_S3_FILE_NAME].join("/")}
          userFileName={[
            data!.name + " analiza-chf",
            EXCEL_OUTPUT_EXTENSION,
          ].join(".")}
        />
      </ExcelSection>
      <Section>
        <Reports caseObject={data!} />
      </Section>
      <Section>
        <EventLog caseId={data!.id} />
      </Section>
    </Container>
  );
};
