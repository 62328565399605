import React, {
  InputHTMLAttributes,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useDropzone } from "react-dropzone";
import { Context } from "../../../components/alerts";
import {
  Button,
  Heading,
  Text,
  Flex,
  View,
  IconClear,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Container, ButtonContainer, Dropzone } from "./styles";
import { submit, CreateCaseState, CreateExcelJobError } from "./side-effects";
import { Loading } from "./loading";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// TODO: Add attribution: <a href="https://www.flaticon.com/free-icons/pdf" title="pdf icons">Pdf icons created by Dimitry Miroliubov - Flaticon</a>
import PdfImage from "../../../pdf.png";
// TODO: Add attribution: <a href="https://www.flaticon.com/free-icons/xls" title="xls icons">Xls icons created by Dimitry Miroliubov - Flaticon</a>
import XlsImage from "../../../xls.png";

const FileRemoveButton = styled(Button)`
  border: none;
  background-color: transparent;
  &:hover {
    border: none;
    background-color: #eeeeee;
  }
`;

const FileImage = styled.img`
  height: 4rem;
  margin-bottom: 0.5rem;
`;

const FileContainerView = styled(View)`
  min-width: 100pt;
`;

const FilePreview = ({
  file,
  onRemove,
}: {
  file: File;
  onRemove: () => void;
}) => {
  const image = file.name.toLowerCase().endsWith("pdf") ? PdfImage : XlsImage;
  return (
    <FileContainerView>
      <Flex direction="column" alignItems="center" gap="0">
        <FileRemoveButton alignSelf="flex-end" gap="10px" onClick={onRemove}>
          <IconClear />
        </FileRemoveButton>
        <FileImage src={image} alt="plik PDF"></FileImage>
        <Text flex={1} fontWeight="bold" textAlign={"center"}>
          {file.name}
        </Text>
      </Flex>
    </FileContainerView>
  );
};

const SelectFile = ({
  getInputProps,
}: {
  getInputProps: () => InputHTMLAttributes<HTMLInputElement>;
}) => (
  <div>
    <input {...getInputProps()} />
    <p>Przeciągnij plik lub kliknij, aby wybrać</p>
  </div>
);

const errorToMessage = (error: CreateExcelJobError | null): string => {
  if (!error) {
    return "";
  }
  switch (error) {
    case CreateExcelJobError.ALREADY_SIGNED:
      return "Plik został już wcześniej przesłany.";
    default:
      return "Nie udało się wysłać pliku. Spróbuj ponownie.";
  }
};

export const Create = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(CreateCaseState.IDLE);
  const isIdle = state === CreateCaseState.IDLE;
  const [file, setFile] = useState<File | null>(null);
  const hasFile = !!file;
  const { setAlert } = React.useContext(Context);
  const onSubmit = React.useCallback(
    async (event) => {
      if (!file) {
        return;
      }
      submit({
        file,
        onStateChange: (state) => {
          setState(state);
        },
        onResult: (result, error) => {
          if (!error && result) {
            navigate(`/case/${result.createExcelJobCaseId}`);
            return;
          }

          console.error("Caught error", error);
          setAlert({
            variation: "error",
            isDismissible: true,
            hasIcon: true,
            heading: errorToMessage(error),
          });
        },
      });
    },
    [setAlert, file, navigate]
  );

  const onDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  };
  const removeFile = useCallback(() => {
    setFile(null);
  }, []);

  useEffect(() => {
    if (state === CreateCaseState.IDLE) removeFile();
  }, [state, removeFile]);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
      onDrop,
      maxFiles: 1,
      disabled: hasFile,
    });

  return isIdle ? (
    <Container>
      <Flex
        width={{ base: "100%", large: "75%" }}
        gap={"1rem"}
        direction={"column"}
      >
        <Heading level={3}>Dodaj sprawę</Heading>
        <Text>
          Aby rozpocząć analizę kredytu dodaj plik .pdf, .xls lub .xlsx
          zawierający historię spłat. Na podstawie przesłanego pliku zostanie
          utworzony specjalny plik .xlsx w celu przeprowadzenia analizy z
          wykorzystaniem rozszerzenia MS Excel.
        </Text>
      </Flex>
      <Dropzone {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        {hasFile && file ? (
          <FilePreview file={file} onRemove={removeFile} />
        ) : (
          <SelectFile getInputProps={getInputProps} />
        )}
      </Dropzone>
      <ButtonContainer>
        <Button
          variation="primary"
          size="large"
          onClick={onSubmit}
          isLoading={state !== CreateCaseState.IDLE}
          isDisabled={!hasFile || state !== CreateCaseState.IDLE}
        >
          Utwórz sprawę
        </Button>
      </ButtonContainer>
    </Container>
  ) : (
    <Loading state={state} />
  );
};
