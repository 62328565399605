import { useState, useEffect } from "react";
import * as R from "ramda";
import {
  CaseEvent,
  CaseEventsByCaseIdAndDateQuery,
  CaseEventType,
} from "../../../API";
import { API } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { caseEventsByCaseIdAndDate } from "../../../graphql/queries";
import {
  Text,
  Collection,
  Heading,
  Placeholder,
  Flex,
  View,
} from "@aws-amplify/ui-react";
import styled from "styled-components";
import { EmptyState } from "../../../components/theme";

const Container = styled(Flex)``;
const SectionContainer = styled(Flex)`
  margin-bottom: 1rem;
`;
const Cell = styled(View)`
  width: 33%;
`;

const fetchCaseEvents = async (
  caseId: string
): Promise<[CaseEvent] | null | undefined> => {
  try {
    const response = (await API.graphql({
      query: caseEventsByCaseIdAndDate,
      variables: {
        caseId,
        sortDirection: "DESC",
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as GraphQLResult<CaseEventsByCaseIdAndDateQuery>;
    return response.data?.caseEventsByCaseIdAndDate?.items as [CaseEvent];
  } catch (err) {
    console.warn("error fetching data..", err);
    return null;
  }
};

const eventName = (event: CaseEventType): string => {
  switch (event) {
    case CaseEventType.GENERATED_REPORT:
      return "Wygenerowanie raportu";
    case CaseEventType.GENERATED_ANALYSIS_COMPARATIVE:
      return "Wygenerowanie symulacji";
    case CaseEventType.GENERATED_ANALYSIS_INVALID_CONTRACT:
      return "Wygenerowanie rozliczenia";
    case CaseEventType.DOWNLOADED_REPORT:
      return "Pobranie raportu";
    case CaseEventType.DOWNLOADED_EXCEL:
      return "Pobranie pliku excel";
  }
};

const Row = ({ index, item }: { index: number; item: CaseEvent }) => {
  const date = new Date(item.date);
  return (
    <Flex key={index}>
      <Cell>
        <Text fontWeight="bold">{eventName(item.type)}</Text>
      </Cell>
      <Cell>
        <Text>{date.toLocaleString()}</Text>
      </Cell>
      <Cell>
        <Text>{item.ownerEmail}</Text>
      </Cell>
    </Flex>
  );
};

export const EventLog = ({ caseId }: { caseId: string }) => {
  const [data, setData] = useState<[CaseEvent] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    fetchCaseEvents(caseId).then((data) => {
      if (!!data) {
        setData(data);
      }
      setIsLoading(false);
    });
  }, [setData, caseId]);

  if (isLoading || !data) {
    return (
      <>
        <Placeholder />
        <Placeholder />
        <Placeholder />
      </>
    );
  }
  const shouldShowEmptyState = R.isEmpty(data);
  return (
    <Container direction={"column"}>
      <SectionContainer direction={"column"}>
        <Heading level={5}>Wykonane operacje</Heading>
        {shouldShowEmptyState ? (
          <EmptyState title={"Brak operacji"} />
        ) : (
          <Collection type="list" items={data!} paddingTop="1rem">
            {(item, index) => <Row index={index} item={item} />}
          </Collection>
        )}
      </SectionContainer>
    </Container>
  );
};
