import { Storage } from "aws-amplify";
import { downloadBlob } from "../../../utils/download";
import { Button, Text, Flex, Image } from "@aws-amplify/ui-react";
import { useCallback } from "react";
import mixpanel from "mixpanel-browser";
import { Event } from "../../../features/analytics";
import styled from "styled-components";
import { createCaseEvent } from "../../../common/api";
import { CaseEventType } from "../../../API";
import Xls from "../../../xls.png";

const Right = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 456px;
  align-items: flex-start;
  justify-content: center;
  gap: var(--amplify-space-small);
`;

const ExcelImage = styled(Image)`
  height: 6rem;
`;

const downloadXls = async (key: string, userFileName: string) => {
  const [id, xlsName] = key.split("/");
  const result = await Storage.get(xlsName, {
    customPrefix: { public: `${id}/` },
    download: true,
  });
  downloadBlob(result.Body, userFileName);
};

export const DownloadExcel = ({
  caseId,
  s3Key,
  userFileName,
}: {
  caseId: string;
  s3Key: string;
  userFileName: string;
}) => {
  const download = useCallback(() => {
    downloadXls(s3Key, userFileName);
    mixpanel.track(Event.ExcelDownloaded, { caseId });
    createCaseEvent({ caseId, type: CaseEventType.DOWNLOADED_EXCEL });
  }, [caseId, s3Key, userFileName]);
  return (
    <Flex>
      <ExcelImage src={Xls} alt="Plik excel" />
      <Right>
        <Button onClick={download} variation={"primary"}>
          Pobierz plik
        </Button>
        <Text>
          Pobierz wygenerowany plik .xls, aby przeprowadzić analizę za pomocą
          dedykowanego rozszerzenia MS Excel.
        </Text>
      </Right>
    </Flex>
  );
};
