/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCase = /* GraphQL */ `
  subscription OnCreateCase($owner: String) {
    onCreateCase(owner: $owner) {
      id
      name
      date
      companyId
      partitionKey
      createExcelJob {
        id
        status
        date
        companyId
        owner
        ownerEmail
        inputType
        outputKey
        case {
          id
          name
          date
          companyId
          partitionKey
          owner
          ownerEmail
          createdAt
          updatedAt
          caseCreateExcelJobId
        }
        createdAt
        updatedAt
        createExcelJobCaseId
      }
      reports {
        items {
          id
          status
          date
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          date
          type
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      ownerEmail
      createdAt
      updatedAt
      caseCreateExcelJobId
    }
  }
`;
export const onUpdateCase = /* GraphQL */ `
  subscription OnUpdateCase($owner: String) {
    onUpdateCase(owner: $owner) {
      id
      name
      date
      companyId
      partitionKey
      createExcelJob {
        id
        status
        date
        companyId
        owner
        ownerEmail
        inputType
        outputKey
        case {
          id
          name
          date
          companyId
          partitionKey
          owner
          ownerEmail
          createdAt
          updatedAt
          caseCreateExcelJobId
        }
        createdAt
        updatedAt
        createExcelJobCaseId
      }
      reports {
        items {
          id
          status
          date
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          date
          type
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      ownerEmail
      createdAt
      updatedAt
      caseCreateExcelJobId
    }
  }
`;
export const onDeleteCase = /* GraphQL */ `
  subscription OnDeleteCase($owner: String) {
    onDeleteCase(owner: $owner) {
      id
      name
      date
      companyId
      partitionKey
      createExcelJob {
        id
        status
        date
        companyId
        owner
        ownerEmail
        inputType
        outputKey
        case {
          id
          name
          date
          companyId
          partitionKey
          owner
          ownerEmail
          createdAt
          updatedAt
          caseCreateExcelJobId
        }
        createdAt
        updatedAt
        createExcelJobCaseId
      }
      reports {
        items {
          id
          status
          date
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          date
          type
          companyId
          owner
          ownerEmail
          caseId
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      ownerEmail
      createdAt
      updatedAt
      caseCreateExcelJobId
    }
  }
`;
export const onCreateCaseEvent = /* GraphQL */ `
  subscription OnCreateCaseEvent($owner: String) {
    onCreateCaseEvent(owner: $owner) {
      id
      date
      type
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCaseEvent = /* GraphQL */ `
  subscription OnUpdateCaseEvent($owner: String) {
    onUpdateCaseEvent(owner: $owner) {
      id
      date
      type
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCaseEvent = /* GraphQL */ `
  subscription OnDeleteCaseEvent($owner: String) {
    onDeleteCaseEvent(owner: $owner) {
      id
      date
      type
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCreateExcelJob = /* GraphQL */ `
  subscription OnCreateCreateExcelJob($owner: String) {
    onCreateCreateExcelJob(owner: $owner) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      inputType
      outputKey
      case {
        id
        name
        date
        companyId
        partitionKey
        createExcelJob {
          id
          status
          date
          companyId
          owner
          ownerEmail
          inputType
          outputKey
          createdAt
          updatedAt
          createExcelJobCaseId
        }
        reports {
          nextToken
        }
        events {
          nextToken
        }
        owner
        ownerEmail
        createdAt
        updatedAt
        caseCreateExcelJobId
      }
      createdAt
      updatedAt
      createExcelJobCaseId
    }
  }
`;
export const onUpdateCreateExcelJob = /* GraphQL */ `
  subscription OnUpdateCreateExcelJob($owner: String) {
    onUpdateCreateExcelJob(owner: $owner) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      inputType
      outputKey
      case {
        id
        name
        date
        companyId
        partitionKey
        createExcelJob {
          id
          status
          date
          companyId
          owner
          ownerEmail
          inputType
          outputKey
          createdAt
          updatedAt
          createExcelJobCaseId
        }
        reports {
          nextToken
        }
        events {
          nextToken
        }
        owner
        ownerEmail
        createdAt
        updatedAt
        caseCreateExcelJobId
      }
      createdAt
      updatedAt
      createExcelJobCaseId
    }
  }
`;
export const onDeleteCreateExcelJob = /* GraphQL */ `
  subscription OnDeleteCreateExcelJob($owner: String) {
    onDeleteCreateExcelJob(owner: $owner) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      inputType
      outputKey
      case {
        id
        name
        date
        companyId
        partitionKey
        createExcelJob {
          id
          status
          date
          companyId
          owner
          ownerEmail
          inputType
          outputKey
          createdAt
          updatedAt
          createExcelJobCaseId
        }
        reports {
          nextToken
        }
        events {
          nextToken
        }
        owner
        ownerEmail
        createdAt
        updatedAt
        caseCreateExcelJobId
      }
      createdAt
      updatedAt
      createExcelJobCaseId
    }
  }
`;
export const onCreateReport = /* GraphQL */ `
  subscription OnCreateReport($owner: String) {
    onCreateReport(owner: $owner) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport($owner: String) {
    onUpdateReport(owner: $owner) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReport = /* GraphQL */ `
  subscription OnDeleteReport($owner: String) {
    onDeleteReport(owner: $owner) {
      id
      status
      date
      companyId
      owner
      ownerEmail
      caseId
      createdAt
      updatedAt
    }
  }
`;
