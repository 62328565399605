import { Alert, AlertProps } from "@aws-amplify/ui-react";
import React from "react";

export const Context = React.createContext({
  setAlert: (alertProps: AlertProps) => {},
});

export const AlertBox = ({ children }: { children: React.ReactElement }) => {
  const [alert, setAlert] = React.useState<AlertProps | undefined>(undefined);
  return (
    <Context.Provider value={{ setAlert }}>
      {!!alert ? <Alert {...alert} /> : null}
      {children}
    </Context.Provider>
  );
};
