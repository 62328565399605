import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import {
  Flex,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Button,
  Heading,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { Case, CasesByDateQuery } from "../../../API";
import { casesByDate } from "../../../graphql/queries";
import { useCallback } from "react";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { CASE_PARTITION_KEY } from "../../../common/constants";
import { onCreateCase } from "../../../graphql/subscriptions";
import Observable from "zen-observable-ts";
import { EmptyState } from "../../../components/theme";
import styled from "styled-components";
import { isCurrentUserAdmin } from "../../../common/user";
import { Filter, FilterState } from "./filter";
import { endOfDay } from "date-fns";

const ClickableTableRow = styled(TableRow)`
  cursor: pointer;
`;

const pageLimit = 50;

const filterAPIVariables = (state: FilterState | null) => {
  if (!state) {
    return {};
  }
  var result = {};
  if (state.startDate && state.endDate) {
    result = {
      date: {
        between: [
          state.startDate.toISOString(),
          endOfDay(state.endDate).toISOString(),
        ],
      },
    };
  }
  if (state.companyId) {
    result = { ...result, filter: { companyId: { eq: state.companyId } } };
  }
  return result;
};

const fetchCasesByDate = async (
  filterState: FilterState | null,
  nextToken: string | null
): Promise<{ nextToken: string | null; cases: Array<Case> }> => {
  try {
    const cases = (await API.graphql({
      query: casesByDate,
      variables: {
        limit: pageLimit,
        partitionKey: CASE_PARTITION_KEY,
        sortDirection: "DESC",
        nextToken,
        ...filterAPIVariables(filterState),
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as GraphQLResult<CasesByDateQuery>;
    return {
      nextToken: cases.data?.casesByDate?.nextToken ?? null,
      cases: (cases.data?.casesByDate?.items ?? []) as Array<Case>,
    };
  } catch (err) {
    console.warn("error fetching data..", err);
    return { nextToken: null, cases: [] };
  }
};

export const List = () => {
  const navigate = useNavigate();
  const [cases, setCases] = useState<Array<Case>>([]);
  const [owner, setOwner] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<FilterState | null>(null);
  const [nextToken, setNextToken] = useState<string | null | undefined>(
    undefined
  );

  const getData = async () => {
    const { cases, nextToken } = await fetchCasesByDate(filterState, null);
    setCases(cases);
    setNextToken(nextToken);
  };

  useEffect(() => {
    getData();
  }, [filterState]);

  useEffect(() => {
    isCurrentUserAdmin().then((isAdmin) => setIsAdmin(isAdmin));
  }, [setIsAdmin]);

  const loadMore = useCallback(async () => {
    const { cases: newCases, nextToken: newNextToken } = await fetchCasesByDate(
      filterState,
      nextToken || null
    );
    setCases(cases.concat(newCases));
    setNextToken(newNextToken);
  }, [cases, setNextToken, nextToken, filterState]);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(({ username }) => {
      setOwner(username);
    });
  }, []);

  useEffect(() => {
    if (!owner) {
      return;
    }
    console.log("Starting subscription");

    const onCreateObservable = API.graphql({
      query: onCreateCase,
      variables: { owner },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }) as Observable<any>;
    const onCreateSubscription = onCreateObservable.subscribe({
      next: (event: any) => {
        const newCase = event.value.data.onCreateCase as Case;
        if (!newCase) {
          console.error(`onCreateCase undefined for event: ${event}`);
          return;
        }
        setCases((cases) => [newCase, ...cases]);
      },
      error: (error) => console.warn(error),
    });

    return () => {
      onCreateSubscription.unsubscribe();
    };
  }, [owner]);

  return (
    <Flex gap={"1rem"} direction="column" minHeight={"100%"}>
      <Flex justifyContent={"space-between"}>
        <Heading level={3}>{"Lista spraw"}</Heading>
        <Link to={`/case/new`} style={{ textDecoration: "none" }}>
          <Button variation="primary">{"Utwórz sprawę"}</Button>
        </Link>
      </Flex>
      {isAdmin ? <Filter onChange={(state) => setFilterState(state)} /> : null}
      {cases.length > 0 ? (
        <div>
          <Table highlightOnHover size={"small"} variation={"striped"}>
            <TableHead>
              <TableRow>
                <TableCell as="th">Data</TableCell>
                <TableCell as="th">Nazwa</TableCell>
                <TableCell as="th">Autor</TableCell>
                {isAdmin ? <TableCell as="th">Kancelaria</TableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {cases.map((caseRow, i) => (
                <ClickableTableRow
                  key={i}
                  onClick={() => navigate(`/case/${caseRow.id}`)}
                >
                  <TableCell>
                    {new Date(caseRow.date).toLocaleString()}
                  </TableCell>
                  <TableCell>{caseRow.name}</TableCell>
                  <TableCell>{caseRow.ownerEmail}</TableCell>
                  {isAdmin ? <TableCell>{caseRow.companyId}</TableCell> : null}
                </ClickableTableRow>
              ))}
            </TableBody>
          </Table>
          <Flex
            direction="row"
            justifyContent={"center"}
            alignItems="center"
            flex={1}
            margin={"3rem 0 0 0"}
          >
            {!!nextToken ? (
              <Button onClick={loadMore}>Załaduj więcej</Button>
            ) : null}
          </Flex>
        </div>
      ) : (
        <EmptyState title="Brak spraw" />
      )}
    </Flex>
  );
};
