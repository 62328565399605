import * as React from "react";
import { Flex, TextField } from "@aws-amplify/ui-react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange, Range } from "react-date-range";
import debounce from "lodash.debounce";

export interface FilterState {
  startDate: Date | undefined;
  endDate: Date | undefined;
  companyId: string | undefined;
}

export const Filter = ({
  onChange,
}: {
  onChange: (state: FilterState) => void;
}) => {
  const [dateRange, setDateRange] = React.useState<Range>({
    startDate: new Date(),
    key: "selection",
  });
  const [companyId, setCompanyId] = React.useState<string | undefined>(
    undefined
  );
  const debouncedOnChange = React.useCallback<(arg0: FilterState) => void>(
    debounce(onChange, 1000),
    []
  );
  React.useEffect(() => {
    debouncedOnChange({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      companyId: companyId,
    });
  }, [dateRange, companyId, debouncedOnChange]);

  return (
    <Flex justifyContent="space-between">
      <DateRange
        editableDateInputs
        onChange={(item) => setDateRange(item.selection)}
        ranges={[dateRange]}
      />
      <TextField
        inputMode="search"
        label={"Kancelaria"}
        placeholder={"Kancelaria"}
        onChange={(event: any) => setCompanyId(event.currentTarget.value)}
      />
    </Flex>
  );
};
