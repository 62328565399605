/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCaseInput = {
  id?: string | null,
  name: string,
  date: string,
  companyId?: string | null,
  partitionKey: string,
  owner: string,
  ownerEmail: string,
  caseCreateExcelJobId: string,
};

export type ModelCaseConditionInput = {
  name?: ModelStringInput | null,
  date?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  partitionKey?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  ownerEmail?: ModelStringInput | null,
  and?: Array< ModelCaseConditionInput | null > | null,
  or?: Array< ModelCaseConditionInput | null > | null,
  not?: ModelCaseConditionInput | null,
  caseCreateExcelJobId?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Case = {
  __typename: "Case",
  id: string,
  name: string,
  date: string,
  companyId?: string | null,
  partitionKey: string,
  createExcelJob: CreateExcelJob,
  reports?: ModelReportConnection | null,
  events?: ModelCaseEventConnection | null,
  owner: string,
  ownerEmail: string,
  createdAt: string,
  updatedAt: string,
  caseCreateExcelJobId: string,
};

export type CreateExcelJob = {
  __typename: "CreateExcelJob",
  id: string,
  status: CreateExcelJobStatus,
  date: string,
  companyId: string,
  owner: string,
  ownerEmail: string,
  inputType: CreateExcelJobInputType,
  outputKey?: string | null,
  case?: Case | null,
  createdAt: string,
  updatedAt: string,
  createExcelJobCaseId?: string | null,
};

export enum CreateExcelJobStatus {
  UPLOADED = "UPLOADED",
  PROCESSING = "PROCESSING",
  FAILED_EXCEL_ALREADY_SIGNED = "FAILED_EXCEL_ALREADY_SIGNED",
  FAILED_TEXTRACT = "FAILED_TEXTRACT",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
}


export enum CreateExcelJobInputType {
  EXCEL = "EXCEL",
  PDF = "PDF",
}


export type ModelReportConnection = {
  __typename: "ModelReportConnection",
  items:  Array<Report | null >,
  nextToken?: string | null,
};

export type Report = {
  __typename: "Report",
  id: string,
  status: ReportStatus,
  date: string,
  companyId?: string | null,
  owner: string,
  ownerEmail: string,
  caseId: string,
  createdAt: string,
  updatedAt: string,
};

export enum ReportStatus {
  UPLOADED = "UPLOADED",
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
}


export type ModelCaseEventConnection = {
  __typename: "ModelCaseEventConnection",
  items:  Array<CaseEvent | null >,
  nextToken?: string | null,
};

export type CaseEvent = {
  __typename: "CaseEvent",
  id: string,
  date: string,
  type: CaseEventType,
  companyId: string,
  owner: string,
  ownerEmail: string,
  caseId: string,
  createdAt: string,
  updatedAt: string,
};

export enum CaseEventType {
  GENERATED_ANALYSIS_INVALID_CONTRACT = "GENERATED_ANALYSIS_INVALID_CONTRACT",
  GENERATED_ANALYSIS_COMPARATIVE = "GENERATED_ANALYSIS_COMPARATIVE",
  GENERATED_REPORT = "GENERATED_REPORT",
  DOWNLOADED_REPORT = "DOWNLOADED_REPORT",
  DOWNLOADED_EXCEL = "DOWNLOADED_EXCEL",
}


export type UpdateCaseInput = {
  id: string,
  name?: string | null,
  date?: string | null,
  companyId?: string | null,
  partitionKey?: string | null,
  owner?: string | null,
  ownerEmail?: string | null,
  caseCreateExcelJobId?: string | null,
};

export type DeleteCaseInput = {
  id: string,
};

export type CreateCaseEventInput = {
  id?: string | null,
  date: string,
  type: CaseEventType,
  companyId: string,
  owner: string,
  ownerEmail: string,
  caseId: string,
};

export type ModelCaseEventConditionInput = {
  date?: ModelStringInput | null,
  type?: ModelCaseEventTypeInput | null,
  companyId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  ownerEmail?: ModelStringInput | null,
  caseId?: ModelIDInput | null,
  and?: Array< ModelCaseEventConditionInput | null > | null,
  or?: Array< ModelCaseEventConditionInput | null > | null,
  not?: ModelCaseEventConditionInput | null,
};

export type ModelCaseEventTypeInput = {
  eq?: CaseEventType | null,
  ne?: CaseEventType | null,
};

export type UpdateCaseEventInput = {
  id: string,
  date?: string | null,
  type?: CaseEventType | null,
  companyId?: string | null,
  owner?: string | null,
  ownerEmail?: string | null,
  caseId?: string | null,
};

export type DeleteCaseEventInput = {
  id: string,
};

export type CreateCreateExcelJobInput = {
  id?: string | null,
  status: CreateExcelJobStatus,
  date: string,
  companyId: string,
  owner: string,
  ownerEmail: string,
  inputType: CreateExcelJobInputType,
  outputKey?: string | null,
  createExcelJobCaseId?: string | null,
};

export type ModelCreateExcelJobConditionInput = {
  status?: ModelCreateExcelJobStatusInput | null,
  date?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  ownerEmail?: ModelStringInput | null,
  inputType?: ModelCreateExcelJobInputTypeInput | null,
  outputKey?: ModelStringInput | null,
  and?: Array< ModelCreateExcelJobConditionInput | null > | null,
  or?: Array< ModelCreateExcelJobConditionInput | null > | null,
  not?: ModelCreateExcelJobConditionInput | null,
  createExcelJobCaseId?: ModelIDInput | null,
};

export type ModelCreateExcelJobStatusInput = {
  eq?: CreateExcelJobStatus | null,
  ne?: CreateExcelJobStatus | null,
};

export type ModelCreateExcelJobInputTypeInput = {
  eq?: CreateExcelJobInputType | null,
  ne?: CreateExcelJobInputType | null,
};

export type UpdateCreateExcelJobInput = {
  id: string,
  status?: CreateExcelJobStatus | null,
  date?: string | null,
  companyId?: string | null,
  owner?: string | null,
  ownerEmail?: string | null,
  inputType?: CreateExcelJobInputType | null,
  outputKey?: string | null,
  createExcelJobCaseId?: string | null,
};

export type DeleteCreateExcelJobInput = {
  id: string,
};

export type CreateReportInput = {
  id?: string | null,
  status: ReportStatus,
  date: string,
  companyId?: string | null,
  owner: string,
  ownerEmail: string,
  caseId: string,
};

export type ModelReportConditionInput = {
  status?: ModelReportStatusInput | null,
  date?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  ownerEmail?: ModelStringInput | null,
  caseId?: ModelIDInput | null,
  and?: Array< ModelReportConditionInput | null > | null,
  or?: Array< ModelReportConditionInput | null > | null,
  not?: ModelReportConditionInput | null,
};

export type ModelReportStatusInput = {
  eq?: ReportStatus | null,
  ne?: ReportStatus | null,
};

export type UpdateReportInput = {
  id: string,
  status?: ReportStatus | null,
  date?: string | null,
  companyId?: string | null,
  owner?: string | null,
  ownerEmail?: string | null,
  caseId?: string | null,
};

export type DeleteReportInput = {
  id: string,
};

export type ModelCaseFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  date?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  partitionKey?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  ownerEmail?: ModelStringInput | null,
  and?: Array< ModelCaseFilterInput | null > | null,
  or?: Array< ModelCaseFilterInput | null > | null,
  not?: ModelCaseFilterInput | null,
  caseCreateExcelJobId?: ModelIDInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCaseConnection = {
  __typename: "ModelCaseConnection",
  items:  Array<Case | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCaseEventFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  type?: ModelCaseEventTypeInput | null,
  companyId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  ownerEmail?: ModelStringInput | null,
  caseId?: ModelIDInput | null,
  and?: Array< ModelCaseEventFilterInput | null > | null,
  or?: Array< ModelCaseEventFilterInput | null > | null,
  not?: ModelCaseEventFilterInput | null,
};

export type ModelCreateExcelJobFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelCreateExcelJobStatusInput | null,
  date?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  ownerEmail?: ModelStringInput | null,
  inputType?: ModelCreateExcelJobInputTypeInput | null,
  outputKey?: ModelStringInput | null,
  and?: Array< ModelCreateExcelJobFilterInput | null > | null,
  or?: Array< ModelCreateExcelJobFilterInput | null > | null,
  not?: ModelCreateExcelJobFilterInput | null,
  createExcelJobCaseId?: ModelIDInput | null,
};

export type ModelCreateExcelJobConnection = {
  __typename: "ModelCreateExcelJobConnection",
  items:  Array<CreateExcelJob | null >,
  nextToken?: string | null,
};

export type ModelReportFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelReportStatusInput | null,
  date?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  ownerEmail?: ModelStringInput | null,
  caseId?: ModelIDInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
};

export type CreateCaseMutationVariables = {
  input: CreateCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type CreateCaseMutation = {
  createCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    date: string,
    companyId?: string | null,
    partitionKey: string,
    createExcelJob:  {
      __typename: "CreateExcelJob",
      id: string,
      status: CreateExcelJobStatus,
      date: string,
      companyId: string,
      owner: string,
      ownerEmail: string,
      inputType: CreateExcelJobInputType,
      outputKey?: string | null,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        date: string,
        companyId?: string | null,
        partitionKey: string,
        owner: string,
        ownerEmail: string,
        createdAt: string,
        updatedAt: string,
        caseCreateExcelJobId: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      createExcelJobCaseId?: string | null,
    },
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        status: ReportStatus,
        date: string,
        companyId?: string | null,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCaseEventConnection",
      items:  Array< {
        __typename: "CaseEvent",
        id: string,
        date: string,
        type: CaseEventType,
        companyId: string,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner: string,
    ownerEmail: string,
    createdAt: string,
    updatedAt: string,
    caseCreateExcelJobId: string,
  } | null,
};

export type UpdateCaseMutationVariables = {
  input: UpdateCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type UpdateCaseMutation = {
  updateCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    date: string,
    companyId?: string | null,
    partitionKey: string,
    createExcelJob:  {
      __typename: "CreateExcelJob",
      id: string,
      status: CreateExcelJobStatus,
      date: string,
      companyId: string,
      owner: string,
      ownerEmail: string,
      inputType: CreateExcelJobInputType,
      outputKey?: string | null,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        date: string,
        companyId?: string | null,
        partitionKey: string,
        owner: string,
        ownerEmail: string,
        createdAt: string,
        updatedAt: string,
        caseCreateExcelJobId: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      createExcelJobCaseId?: string | null,
    },
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        status: ReportStatus,
        date: string,
        companyId?: string | null,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCaseEventConnection",
      items:  Array< {
        __typename: "CaseEvent",
        id: string,
        date: string,
        type: CaseEventType,
        companyId: string,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner: string,
    ownerEmail: string,
    createdAt: string,
    updatedAt: string,
    caseCreateExcelJobId: string,
  } | null,
};

export type DeleteCaseMutationVariables = {
  input: DeleteCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type DeleteCaseMutation = {
  deleteCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    date: string,
    companyId?: string | null,
    partitionKey: string,
    createExcelJob:  {
      __typename: "CreateExcelJob",
      id: string,
      status: CreateExcelJobStatus,
      date: string,
      companyId: string,
      owner: string,
      ownerEmail: string,
      inputType: CreateExcelJobInputType,
      outputKey?: string | null,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        date: string,
        companyId?: string | null,
        partitionKey: string,
        owner: string,
        ownerEmail: string,
        createdAt: string,
        updatedAt: string,
        caseCreateExcelJobId: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      createExcelJobCaseId?: string | null,
    },
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        status: ReportStatus,
        date: string,
        companyId?: string | null,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCaseEventConnection",
      items:  Array< {
        __typename: "CaseEvent",
        id: string,
        date: string,
        type: CaseEventType,
        companyId: string,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner: string,
    ownerEmail: string,
    createdAt: string,
    updatedAt: string,
    caseCreateExcelJobId: string,
  } | null,
};

export type CreateCaseEventMutationVariables = {
  input: CreateCaseEventInput,
  condition?: ModelCaseEventConditionInput | null,
};

export type CreateCaseEventMutation = {
  createCaseEvent?:  {
    __typename: "CaseEvent",
    id: string,
    date: string,
    type: CaseEventType,
    companyId: string,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCaseEventMutationVariables = {
  input: UpdateCaseEventInput,
  condition?: ModelCaseEventConditionInput | null,
};

export type UpdateCaseEventMutation = {
  updateCaseEvent?:  {
    __typename: "CaseEvent",
    id: string,
    date: string,
    type: CaseEventType,
    companyId: string,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCaseEventMutationVariables = {
  input: DeleteCaseEventInput,
  condition?: ModelCaseEventConditionInput | null,
};

export type DeleteCaseEventMutation = {
  deleteCaseEvent?:  {
    __typename: "CaseEvent",
    id: string,
    date: string,
    type: CaseEventType,
    companyId: string,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCreateExcelJobMutationVariables = {
  input: CreateCreateExcelJobInput,
  condition?: ModelCreateExcelJobConditionInput | null,
};

export type CreateCreateExcelJobMutation = {
  createCreateExcelJob?:  {
    __typename: "CreateExcelJob",
    id: string,
    status: CreateExcelJobStatus,
    date: string,
    companyId: string,
    owner: string,
    ownerEmail: string,
    inputType: CreateExcelJobInputType,
    outputKey?: string | null,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      date: string,
      companyId?: string | null,
      partitionKey: string,
      createExcelJob:  {
        __typename: "CreateExcelJob",
        id: string,
        status: CreateExcelJobStatus,
        date: string,
        companyId: string,
        owner: string,
        ownerEmail: string,
        inputType: CreateExcelJobInputType,
        outputKey?: string | null,
        createdAt: string,
        updatedAt: string,
        createExcelJobCaseId?: string | null,
      },
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCaseEventConnection",
        nextToken?: string | null,
      } | null,
      owner: string,
      ownerEmail: string,
      createdAt: string,
      updatedAt: string,
      caseCreateExcelJobId: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    createExcelJobCaseId?: string | null,
  } | null,
};

export type UpdateCreateExcelJobMutationVariables = {
  input: UpdateCreateExcelJobInput,
  condition?: ModelCreateExcelJobConditionInput | null,
};

export type UpdateCreateExcelJobMutation = {
  updateCreateExcelJob?:  {
    __typename: "CreateExcelJob",
    id: string,
    status: CreateExcelJobStatus,
    date: string,
    companyId: string,
    owner: string,
    ownerEmail: string,
    inputType: CreateExcelJobInputType,
    outputKey?: string | null,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      date: string,
      companyId?: string | null,
      partitionKey: string,
      createExcelJob:  {
        __typename: "CreateExcelJob",
        id: string,
        status: CreateExcelJobStatus,
        date: string,
        companyId: string,
        owner: string,
        ownerEmail: string,
        inputType: CreateExcelJobInputType,
        outputKey?: string | null,
        createdAt: string,
        updatedAt: string,
        createExcelJobCaseId?: string | null,
      },
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCaseEventConnection",
        nextToken?: string | null,
      } | null,
      owner: string,
      ownerEmail: string,
      createdAt: string,
      updatedAt: string,
      caseCreateExcelJobId: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    createExcelJobCaseId?: string | null,
  } | null,
};

export type DeleteCreateExcelJobMutationVariables = {
  input: DeleteCreateExcelJobInput,
  condition?: ModelCreateExcelJobConditionInput | null,
};

export type DeleteCreateExcelJobMutation = {
  deleteCreateExcelJob?:  {
    __typename: "CreateExcelJob",
    id: string,
    status: CreateExcelJobStatus,
    date: string,
    companyId: string,
    owner: string,
    ownerEmail: string,
    inputType: CreateExcelJobInputType,
    outputKey?: string | null,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      date: string,
      companyId?: string | null,
      partitionKey: string,
      createExcelJob:  {
        __typename: "CreateExcelJob",
        id: string,
        status: CreateExcelJobStatus,
        date: string,
        companyId: string,
        owner: string,
        ownerEmail: string,
        inputType: CreateExcelJobInputType,
        outputKey?: string | null,
        createdAt: string,
        updatedAt: string,
        createExcelJobCaseId?: string | null,
      },
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCaseEventConnection",
        nextToken?: string | null,
      } | null,
      owner: string,
      ownerEmail: string,
      createdAt: string,
      updatedAt: string,
      caseCreateExcelJobId: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    createExcelJobCaseId?: string | null,
  } | null,
};

export type CreateReportMutationVariables = {
  input: CreateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    id: string,
    status: ReportStatus,
    date: string,
    companyId?: string | null,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReportMutationVariables = {
  input: UpdateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    id: string,
    status: ReportStatus,
    date: string,
    companyId?: string | null,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReportMutationVariables = {
  input: DeleteReportInput,
  condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
  deleteReport?:  {
    __typename: "Report",
    id: string,
    status: ReportStatus,
    date: string,
    companyId?: string | null,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetCaseQueryVariables = {
  id: string,
};

export type GetCaseQuery = {
  getCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    date: string,
    companyId?: string | null,
    partitionKey: string,
    createExcelJob:  {
      __typename: "CreateExcelJob",
      id: string,
      status: CreateExcelJobStatus,
      date: string,
      companyId: string,
      owner: string,
      ownerEmail: string,
      inputType: CreateExcelJobInputType,
      outputKey?: string | null,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        date: string,
        companyId?: string | null,
        partitionKey: string,
        owner: string,
        ownerEmail: string,
        createdAt: string,
        updatedAt: string,
        caseCreateExcelJobId: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      createExcelJobCaseId?: string | null,
    },
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        status: ReportStatus,
        date: string,
        companyId?: string | null,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCaseEventConnection",
      items:  Array< {
        __typename: "CaseEvent",
        id: string,
        date: string,
        type: CaseEventType,
        companyId: string,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner: string,
    ownerEmail: string,
    createdAt: string,
    updatedAt: string,
    caseCreateExcelJobId: string,
  } | null,
};

export type ListCasesQueryVariables = {
  id?: string | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCasesQuery = {
  listCases?:  {
    __typename: "ModelCaseConnection",
    items:  Array< {
      __typename: "Case",
      id: string,
      name: string,
      date: string,
      companyId?: string | null,
      partitionKey: string,
      createExcelJob:  {
        __typename: "CreateExcelJob",
        id: string,
        status: CreateExcelJobStatus,
        date: string,
        companyId: string,
        owner: string,
        ownerEmail: string,
        inputType: CreateExcelJobInputType,
        outputKey?: string | null,
        createdAt: string,
        updatedAt: string,
        createExcelJobCaseId?: string | null,
      },
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCaseEventConnection",
        nextToken?: string | null,
      } | null,
      owner: string,
      ownerEmail: string,
      createdAt: string,
      updatedAt: string,
      caseCreateExcelJobId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CaseByCompanyIdAndDateQueryVariables = {
  companyId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CaseByCompanyIdAndDateQuery = {
  caseByCompanyIdAndDate?:  {
    __typename: "ModelCaseConnection",
    items:  Array< {
      __typename: "Case",
      id: string,
      name: string,
      date: string,
      companyId?: string | null,
      partitionKey: string,
      createExcelJob:  {
        __typename: "CreateExcelJob",
        id: string,
        status: CreateExcelJobStatus,
        date: string,
        companyId: string,
        owner: string,
        ownerEmail: string,
        inputType: CreateExcelJobInputType,
        outputKey?: string | null,
        createdAt: string,
        updatedAt: string,
        createExcelJobCaseId?: string | null,
      },
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCaseEventConnection",
        nextToken?: string | null,
      } | null,
      owner: string,
      ownerEmail: string,
      createdAt: string,
      updatedAt: string,
      caseCreateExcelJobId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CasesByDateQueryVariables = {
  partitionKey: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CasesByDateQuery = {
  casesByDate?:  {
    __typename: "ModelCaseConnection",
    items:  Array< {
      __typename: "Case",
      id: string,
      name: string,
      date: string,
      companyId?: string | null,
      partitionKey: string,
      createExcelJob:  {
        __typename: "CreateExcelJob",
        id: string,
        status: CreateExcelJobStatus,
        date: string,
        companyId: string,
        owner: string,
        ownerEmail: string,
        inputType: CreateExcelJobInputType,
        outputKey?: string | null,
        createdAt: string,
        updatedAt: string,
        createExcelJobCaseId?: string | null,
      },
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCaseEventConnection",
        nextToken?: string | null,
      } | null,
      owner: string,
      ownerEmail: string,
      createdAt: string,
      updatedAt: string,
      caseCreateExcelJobId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCaseEventQueryVariables = {
  id: string,
};

export type GetCaseEventQuery = {
  getCaseEvent?:  {
    __typename: "CaseEvent",
    id: string,
    date: string,
    type: CaseEventType,
    companyId: string,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCaseEventsQueryVariables = {
  id?: string | null,
  filter?: ModelCaseEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCaseEventsQuery = {
  listCaseEvents?:  {
    __typename: "ModelCaseEventConnection",
    items:  Array< {
      __typename: "CaseEvent",
      id: string,
      date: string,
      type: CaseEventType,
      companyId: string,
      owner: string,
      ownerEmail: string,
      caseId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CaseEventsByCaseIdAndDateQueryVariables = {
  caseId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCaseEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CaseEventsByCaseIdAndDateQuery = {
  caseEventsByCaseIdAndDate?:  {
    __typename: "ModelCaseEventConnection",
    items:  Array< {
      __typename: "CaseEvent",
      id: string,
      date: string,
      type: CaseEventType,
      companyId: string,
      owner: string,
      ownerEmail: string,
      caseId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCreateExcelJobQueryVariables = {
  id: string,
};

export type GetCreateExcelJobQuery = {
  getCreateExcelJob?:  {
    __typename: "CreateExcelJob",
    id: string,
    status: CreateExcelJobStatus,
    date: string,
    companyId: string,
    owner: string,
    ownerEmail: string,
    inputType: CreateExcelJobInputType,
    outputKey?: string | null,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      date: string,
      companyId?: string | null,
      partitionKey: string,
      createExcelJob:  {
        __typename: "CreateExcelJob",
        id: string,
        status: CreateExcelJobStatus,
        date: string,
        companyId: string,
        owner: string,
        ownerEmail: string,
        inputType: CreateExcelJobInputType,
        outputKey?: string | null,
        createdAt: string,
        updatedAt: string,
        createExcelJobCaseId?: string | null,
      },
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCaseEventConnection",
        nextToken?: string | null,
      } | null,
      owner: string,
      ownerEmail: string,
      createdAt: string,
      updatedAt: string,
      caseCreateExcelJobId: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    createExcelJobCaseId?: string | null,
  } | null,
};

export type ListCreateExcelJobsQueryVariables = {
  id?: string | null,
  filter?: ModelCreateExcelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCreateExcelJobsQuery = {
  listCreateExcelJobs?:  {
    __typename: "ModelCreateExcelJobConnection",
    items:  Array< {
      __typename: "CreateExcelJob",
      id: string,
      status: CreateExcelJobStatus,
      date: string,
      companyId: string,
      owner: string,
      ownerEmail: string,
      inputType: CreateExcelJobInputType,
      outputKey?: string | null,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        date: string,
        companyId?: string | null,
        partitionKey: string,
        owner: string,
        ownerEmail: string,
        createdAt: string,
        updatedAt: string,
        caseCreateExcelJobId: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      createExcelJobCaseId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type JobsByCompanyIdAndDateQueryVariables = {
  companyId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCreateExcelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type JobsByCompanyIdAndDateQuery = {
  jobsByCompanyIdAndDate?:  {
    __typename: "ModelCreateExcelJobConnection",
    items:  Array< {
      __typename: "CreateExcelJob",
      id: string,
      status: CreateExcelJobStatus,
      date: string,
      companyId: string,
      owner: string,
      ownerEmail: string,
      inputType: CreateExcelJobInputType,
      outputKey?: string | null,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        date: string,
        companyId?: string | null,
        partitionKey: string,
        owner: string,
        ownerEmail: string,
        createdAt: string,
        updatedAt: string,
        caseCreateExcelJobId: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      createExcelJobCaseId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportQueryVariables = {
  id: string,
};

export type GetReportQuery = {
  getReport?:  {
    __typename: "Report",
    id: string,
    status: ReportStatus,
    date: string,
    companyId?: string | null,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReportsQueryVariables = {
  id?: string | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      status: ReportStatus,
      date: string,
      companyId?: string | null,
      owner: string,
      ownerEmail: string,
      caseId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReportsByCompanyIdAndDateQueryVariables = {
  companyId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportsByCompanyIdAndDateQuery = {
  reportsByCompanyIdAndDate?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      status: ReportStatus,
      date: string,
      companyId?: string | null,
      owner: string,
      ownerEmail: string,
      caseId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReportsByCaseIdAndDateQueryVariables = {
  caseId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportsByCaseIdAndDateQuery = {
  reportsByCaseIdAndDate?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      status: ReportStatus,
      date: string,
      companyId?: string | null,
      owner: string,
      ownerEmail: string,
      caseId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateCaseSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCaseSubscription = {
  onCreateCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    date: string,
    companyId?: string | null,
    partitionKey: string,
    createExcelJob:  {
      __typename: "CreateExcelJob",
      id: string,
      status: CreateExcelJobStatus,
      date: string,
      companyId: string,
      owner: string,
      ownerEmail: string,
      inputType: CreateExcelJobInputType,
      outputKey?: string | null,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        date: string,
        companyId?: string | null,
        partitionKey: string,
        owner: string,
        ownerEmail: string,
        createdAt: string,
        updatedAt: string,
        caseCreateExcelJobId: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      createExcelJobCaseId?: string | null,
    },
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        status: ReportStatus,
        date: string,
        companyId?: string | null,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCaseEventConnection",
      items:  Array< {
        __typename: "CaseEvent",
        id: string,
        date: string,
        type: CaseEventType,
        companyId: string,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner: string,
    ownerEmail: string,
    createdAt: string,
    updatedAt: string,
    caseCreateExcelJobId: string,
  } | null,
};

export type OnUpdateCaseSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCaseSubscription = {
  onUpdateCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    date: string,
    companyId?: string | null,
    partitionKey: string,
    createExcelJob:  {
      __typename: "CreateExcelJob",
      id: string,
      status: CreateExcelJobStatus,
      date: string,
      companyId: string,
      owner: string,
      ownerEmail: string,
      inputType: CreateExcelJobInputType,
      outputKey?: string | null,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        date: string,
        companyId?: string | null,
        partitionKey: string,
        owner: string,
        ownerEmail: string,
        createdAt: string,
        updatedAt: string,
        caseCreateExcelJobId: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      createExcelJobCaseId?: string | null,
    },
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        status: ReportStatus,
        date: string,
        companyId?: string | null,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCaseEventConnection",
      items:  Array< {
        __typename: "CaseEvent",
        id: string,
        date: string,
        type: CaseEventType,
        companyId: string,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner: string,
    ownerEmail: string,
    createdAt: string,
    updatedAt: string,
    caseCreateExcelJobId: string,
  } | null,
};

export type OnDeleteCaseSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCaseSubscription = {
  onDeleteCase?:  {
    __typename: "Case",
    id: string,
    name: string,
    date: string,
    companyId?: string | null,
    partitionKey: string,
    createExcelJob:  {
      __typename: "CreateExcelJob",
      id: string,
      status: CreateExcelJobStatus,
      date: string,
      companyId: string,
      owner: string,
      ownerEmail: string,
      inputType: CreateExcelJobInputType,
      outputKey?: string | null,
      case?:  {
        __typename: "Case",
        id: string,
        name: string,
        date: string,
        companyId?: string | null,
        partitionKey: string,
        owner: string,
        ownerEmail: string,
        createdAt: string,
        updatedAt: string,
        caseCreateExcelJobId: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      createExcelJobCaseId?: string | null,
    },
    reports?:  {
      __typename: "ModelReportConnection",
      items:  Array< {
        __typename: "Report",
        id: string,
        status: ReportStatus,
        date: string,
        companyId?: string | null,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCaseEventConnection",
      items:  Array< {
        __typename: "CaseEvent",
        id: string,
        date: string,
        type: CaseEventType,
        companyId: string,
        owner: string,
        ownerEmail: string,
        caseId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner: string,
    ownerEmail: string,
    createdAt: string,
    updatedAt: string,
    caseCreateExcelJobId: string,
  } | null,
};

export type OnCreateCaseEventSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCaseEventSubscription = {
  onCreateCaseEvent?:  {
    __typename: "CaseEvent",
    id: string,
    date: string,
    type: CaseEventType,
    companyId: string,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCaseEventSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCaseEventSubscription = {
  onUpdateCaseEvent?:  {
    __typename: "CaseEvent",
    id: string,
    date: string,
    type: CaseEventType,
    companyId: string,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCaseEventSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCaseEventSubscription = {
  onDeleteCaseEvent?:  {
    __typename: "CaseEvent",
    id: string,
    date: string,
    type: CaseEventType,
    companyId: string,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCreateExcelJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCreateExcelJobSubscription = {
  onCreateCreateExcelJob?:  {
    __typename: "CreateExcelJob",
    id: string,
    status: CreateExcelJobStatus,
    date: string,
    companyId: string,
    owner: string,
    ownerEmail: string,
    inputType: CreateExcelJobInputType,
    outputKey?: string | null,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      date: string,
      companyId?: string | null,
      partitionKey: string,
      createExcelJob:  {
        __typename: "CreateExcelJob",
        id: string,
        status: CreateExcelJobStatus,
        date: string,
        companyId: string,
        owner: string,
        ownerEmail: string,
        inputType: CreateExcelJobInputType,
        outputKey?: string | null,
        createdAt: string,
        updatedAt: string,
        createExcelJobCaseId?: string | null,
      },
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCaseEventConnection",
        nextToken?: string | null,
      } | null,
      owner: string,
      ownerEmail: string,
      createdAt: string,
      updatedAt: string,
      caseCreateExcelJobId: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    createExcelJobCaseId?: string | null,
  } | null,
};

export type OnUpdateCreateExcelJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCreateExcelJobSubscription = {
  onUpdateCreateExcelJob?:  {
    __typename: "CreateExcelJob",
    id: string,
    status: CreateExcelJobStatus,
    date: string,
    companyId: string,
    owner: string,
    ownerEmail: string,
    inputType: CreateExcelJobInputType,
    outputKey?: string | null,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      date: string,
      companyId?: string | null,
      partitionKey: string,
      createExcelJob:  {
        __typename: "CreateExcelJob",
        id: string,
        status: CreateExcelJobStatus,
        date: string,
        companyId: string,
        owner: string,
        ownerEmail: string,
        inputType: CreateExcelJobInputType,
        outputKey?: string | null,
        createdAt: string,
        updatedAt: string,
        createExcelJobCaseId?: string | null,
      },
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCaseEventConnection",
        nextToken?: string | null,
      } | null,
      owner: string,
      ownerEmail: string,
      createdAt: string,
      updatedAt: string,
      caseCreateExcelJobId: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    createExcelJobCaseId?: string | null,
  } | null,
};

export type OnDeleteCreateExcelJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCreateExcelJobSubscription = {
  onDeleteCreateExcelJob?:  {
    __typename: "CreateExcelJob",
    id: string,
    status: CreateExcelJobStatus,
    date: string,
    companyId: string,
    owner: string,
    ownerEmail: string,
    inputType: CreateExcelJobInputType,
    outputKey?: string | null,
    case?:  {
      __typename: "Case",
      id: string,
      name: string,
      date: string,
      companyId?: string | null,
      partitionKey: string,
      createExcelJob:  {
        __typename: "CreateExcelJob",
        id: string,
        status: CreateExcelJobStatus,
        date: string,
        companyId: string,
        owner: string,
        ownerEmail: string,
        inputType: CreateExcelJobInputType,
        outputKey?: string | null,
        createdAt: string,
        updatedAt: string,
        createExcelJobCaseId?: string | null,
      },
      reports?:  {
        __typename: "ModelReportConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCaseEventConnection",
        nextToken?: string | null,
      } | null,
      owner: string,
      ownerEmail: string,
      createdAt: string,
      updatedAt: string,
      caseCreateExcelJobId: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    createExcelJobCaseId?: string | null,
  } | null,
};

export type OnCreateReportSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateReportSubscription = {
  onCreateReport?:  {
    __typename: "Report",
    id: string,
    status: ReportStatus,
    date: string,
    companyId?: string | null,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReportSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateReportSubscription = {
  onUpdateReport?:  {
    __typename: "Report",
    id: string,
    status: ReportStatus,
    date: string,
    companyId?: string | null,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReportSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteReportSubscription = {
  onDeleteReport?:  {
    __typename: "Report",
    id: string,
    status: ReportStatus,
    date: string,
    companyId?: string | null,
    owner: string,
    ownerEmail: string,
    caseId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
