import styled from "styled-components";
interface DropzoneState {
  isDragAccept: boolean;
  isDragReject: boolean;
  isFocused: boolean;
}

const getColor = ({ isDragAccept, isDragReject, isFocused }: DropzoneState) => {
  if (isDragAccept) {
    return "#00e676";
  }
  if (isDragReject) {
    return "#ff1744";
  }
  if (isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Dropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props: DropzoneState) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;
