import {
  CreateExcelJobStatus,
  CreateExcelJobInputType,
  CreateCreateExcelJobMutation,
  CreateExcelJob,
  GetCreateExcelJobQuery,
} from "../../../API";
import { userCompanyId } from "../../../common/user";
import { Storage, API, Auth } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { createCreateExcelJob } from "../../../graphql/mutations";
import * as subscriptions from "../../../graphql/subscriptions";
import { v4 as uuid } from "uuid";
import * as mixpanel from "mixpanel-browser";
import { Event } from "../../../features/analytics";
import Observable from "zen-observable-ts";
import { getCreateExcelJob } from "../../../graphql/queries";
import { PROCESSING_TIMEOUT_MS } from "../../../common/constants";
import { isExcelJobStatusFinished } from "../../../model/create-excel-job";

export enum CreateCaseState {
  IDLE,
  UPLOADING,
  PROCESSING,
}

export enum CreateExcelJobError {
  UNKNOWN,
  ALREADY_SIGNED,
  TIMEOUT,
  UNABLE_TO_FETCH_EXCEL_JOB,
}

const excelJobStatusToError = (
  status: CreateExcelJobStatus
): CreateExcelJobError | null => {
  switch (status) {
    case CreateExcelJobStatus.FAILED:
    case CreateExcelJobStatus.FAILED_TEXTRACT:
      return CreateExcelJobError.UNKNOWN;
    case CreateExcelJobStatus.FAILED_EXCEL_ALREADY_SIGNED:
      return CreateExcelJobError.ALREADY_SIGNED;
    default:
      return null;
  }
};

const jobInputType = (file: File): CreateExcelJobInputType | null => {
  switch (file.type) {
    case "application/pdf":
      return CreateExcelJobInputType.PDF;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
      return CreateExcelJobInputType.EXCEL;
    default:
      return null;
  }
};

async function poll<T>(
  fn: () => Promise<T>,
  fnCondition: (result: T) => boolean,
  ms: number
): Promise<T> {
  let result = await fn().catch(() => null);
  while (!result || fnCondition(result)) {
    await wait(ms);
    result = await fn().catch(() => null);
  }
  return result;
}

function wait(ms: number = 1000) {
  return new Promise((resolve) => {
    console.log(`waiting ${ms} ms...`);
    setTimeout(resolve, ms);
  });
}

const upload = async (id: string, file: File) => {
  await Storage.put(file.name, file, {
    customPrefix: { public: `${id}/` },
    bucket: process.env.REACT_APP_CASE_INPUT_BUCKET,
  });
};

const create = async (
  id: string,
  fileName: string,
  inputType: CreateExcelJobInputType
): Promise<GraphQLResult<CreateCreateExcelJobMutation>> => {
  const companyId = await userCompanyId();
  const userInfo = await Auth.currentUserInfo();
  const result = await API.graphql({
    query: createCreateExcelJob,
    variables: {
      input: {
        id,
        status: CreateExcelJobStatus.UPLOADED,
        date: new Date().toISOString(),
        companyId,
        owner: userInfo.username,
        ownerEmail: userInfo.attributes.email,
        inputType,
      },
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  return result as GraphQLResult<CreateCreateExcelJobMutation>;
};

// TODO: add race stream with timeout
const waitForFinishedStatus = async (id: string): Promise<CreateExcelJob> => {
  return new Promise(async (resolve, reject) => {
    const user = await Auth.currentAuthenticatedUser();
    const query = API.graphql({
      query: subscriptions.onUpdateCreateExcelJob,
      variables: { owner: user.username },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }) as Observable<any>;

    const subscription = query.subscribe({
      next: (event: any) => {
        const updatedJob = event.value.data
          .onUpdateCreateExcelJob as CreateExcelJob;
        if (!updatedJob) {
          console.error(`onUpdateOcrJob undefined for event: ${event}`);
          return;
        }
        if (
          updatedJob.id === id &&
          isExcelJobStatusFinished(updatedJob.status)
        ) {
          resolve(updatedJob);
          subscription.unsubscribe();
        }
      },
      error: (error) => {
        console.error(`onUpdateCreateExcelJob failed ${error}`);
        reject(error);
      },
    });
  });
};

const fetchExcelJob = async (id: string): Promise<CreateExcelJob> => {
  const result = (await API.graphql({
    query: getCreateExcelJob,
    variables: {
      id,
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })) as GraphQLResult<GetCreateExcelJobQuery>;
  const job = result?.data?.getCreateExcelJob;
  if (!job) {
    throw CreateExcelJobError.UNABLE_TO_FETCH_EXCEL_JOB;
  }
  return job as CreateExcelJob;
};

const pollExcelJobStatus = (id: string): Promise<CreateExcelJob> =>
  poll(
    () => fetchExcelJob(id),
    (job: CreateExcelJob) => !isExcelJobStatusFinished(job.status),
    5000
  );

// TODO: Add unit tests
export const submit = ({
  onStateChange,
  onResult,
  file,
}: {
  onStateChange: (state: CreateCaseState) => void;
  onResult: (
    job: CreateExcelJob | null,
    error: CreateExcelJobError | null
  ) => void;
  file: File;
}) => {
  onStateChange(CreateCaseState.UPLOADING);
  const id = uuid();
  const type = jobInputType(file);
  if (!type) {
    return;
  }
  upload(id, file)
    .then(() => create(id, file.name, type))
    .then(() => {
      onStateChange(CreateCaseState.PROCESSING);
      mixpanel.track(Event.CreatedExcelJob, { jobId: id, type });
    })
    .then(() => {
      const timeout = new Promise<CreateExcelJob>((resolve, reject) => {
        setTimeout(
          () => reject(CreateExcelJobError.TIMEOUT),
          PROCESSING_TIMEOUT_MS
        );
      });
      const checkStatus = waitForFinishedStatus(id).catch(() => {
        console.log("Caught subscription error, falling back to polling");
        return pollExcelJobStatus(id);
      });
      return Promise.race([timeout, checkStatus]);
    })
    .then((job) => {
      onResult(job, excelJobStatusToError(job.status));
      onStateChange(CreateCaseState.IDLE);
    })
    .catch((error) => {
      console.error("Caught error", error);
      onStateChange(CreateCaseState.IDLE);
      onResult(null, error);
    });
};
