import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { List } from "./list";
import { Detail } from "./detail";
import { Create } from "./create";
import styled from "styled-components";
import { Outlet, useParams } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  gap: 30px;
`;

export const Case = () => (
  <Container>
    <Outlet />
  </Container>
);

export const CaseList = () => <List />;

export const CaseDetail = () => {
  const { caseId } = useParams();

  return <Detail caseId={caseId} />;
};

export const CaseNew = () => <Create />;
