// @ts-ignore
export const downloadBlob = (blob: Readable | Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "analysis.xlsx";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

export const setExtension = (fileName: string, extension: string): string =>
  [fileName.substring(0, fileName.lastIndexOf(".")), extension].join(".");
